import React, { useState, useEffect } from "react";
import {
  Space,
  Table,
  Tag,
  Modal,
  Button,
  Checkbox,
  Form,
  Input,
  Spin,
  Select,
} from "antd";
import logo from "../../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import AdminHeader from "../shared/header";
import AdminFooter from "../shared/footer";
import AdminNav from "../shared/nav";
import PurchaseScheme from "../../shared/purchasescheme";
import PlotList from "../../shared/plotlist";
// import EditEmployee from '../modal/editemployee';
import { getData, getCustomerDropdownList, addData } from "../../../services/services";
import { adminPlotsListAPI, customersAdminListAPI, addToCartAPI } from "../../../common/constants";

function AdminplotList() {
  const [plots, setPlots] = useState([]);
  const [custList, setCustList] = useState([]);
  const [showEmployeeDetails, setShowEmployeeDetails] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState({});
  const [localCart, setLocalCart] = useState({});
  const [custReferrenceList, setCustReferrenceList] = useState([]);
  const [customerID, setCustomerId] = useState("");

  async function fetchPlots() {
    const res = await getData(adminPlotsListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setPlots(listItems);
    }
  }
  useEffect(() => {
    fetchPlots();
  }, []);

  async function fetchCustomersList() {
    const res = await getData(customersAdminListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setCustList(listItems);
      const list = getCustomerDropdownList(listItems);
        setCustReferrenceList(list);
    }
  }
  useEffect(() => {
    fetchCustomersList();
  }, []);

  const handleSelectCustomer =(id) => {
    var customerData = custList.filter((cust) => { return cust.cust_id == id});
    setSelectedEmp(customerData[0]);
    setShowEmployeeDetails(true);
    let cartData = {};
    const cartItems = JSON.parse(localStorage.getItem("bookedPlots"));
    cartData.customerId = id;
    cartData.Items = cartItems;
    setLocalCart(cartData);
    setCustomerId(id);
    return;
  }

  const handleAddtoCart = async () => {
    const response = await addData(addToCartAPI, localCart);
    const result = await response.json();
      if (result && result.status === "success") {
        window.location.replace("/PurchaseCart?cust_id="+customerID);
      } else {
        alert(result.message);
      }
    return;
  }
  const SelectCustomer = () => {
    return (
        <div className='nobgform '>
            <Form className="form"
                layout="vertical">
                <Form.Item
                    label="Select Customer"
                    name="state"
                    rules={[
                        {
                            required: true,
                            message: 'Please select customer!',
                        },
                    ]}
                >
                    <Select
                        allowClear
                        style={{ width: '100%' }}
                        placeholder="Please select"
                        options={custReferrenceList}
                        onSelect={(value) => handleSelectCustomer(value)}
                    />
                </Form.Item>
            </Form>
            {showEmployeeDetails && (
              <div className='emp-dashboard propertylist '>
              <div className='propertylist '>
                  <article className="plot-details customerart">

                      <div className="flex flex-column">
                          <dl>
                              <dt>First Name</dt>
                              <dd>{selectedEmp.name}</dd>
                          </dl>
                          <dl>
                              <dt>Last Name</dt>
                              <dd>{selectedEmp.last_name}</dd>
                          </dl>
                          <dl>
                              <dt>Mobile</dt>
                              <dd>{selectedEmp.mobile_number}</dd>
                          </dl>
                          <dl>
                              <dt>Address 1</dt>
                              <dd>{selectedEmp.address1}</dd>
                          </dl>
                          <dl>
                              <dt>Address 2</dt>
                              <dd>
                              {selectedEmp.address2}
                              </dd>
                          </dl>
                          <dl>
                              <dt>City</dt>
                              <dd>
                              {selectedEmp.city}
                              </dd>
                          </dl>
                          <dl>
                              <dt>State</dt>
                              <dd>
                              {selectedEmp.state}
                              </dd>
                          </dl>

                      </div>
                  </article>
              </div>
          </div>
            )}
            
            <div className="flex end top-gutter-20">
            <Link
                onClick={handleAddtoCart}
                className="ant-btn-secondary bottom-gutter self-end"
              >
               Add to Cart
              </Link>
              </div>
        </div>
    )
}
  
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    const bookedPlots = localStorage.getItem("bookedPlots");
    if(!bookedPlots) {
      alert('Please select Plot before proceed');
      return;
    } else if(bookedPlots) {
      const plots = JSON.parse(bookedPlots);
      if(plots.length == 0) {
        alert('Please select plot before proceed');
        return;
      }
      if(plots.length > 0) {
        let planTypeAvail = true;
        let planTypeErrorMessage = "Please select plan type for Plot/s";
        for(let i=0;i<plots.length;i++) {
          if(plots[i].planType == '') {
            planTypeAvail = false;
          } 
        }
        if(!planTypeAvail){
          alert(planTypeErrorMessage);
          return;
        }
      } else {
        alert('Something wrong in selecting plot');
        return;
      }
    } else {
      alert('Something Wrong in Selecting plot')
      return;
      
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="admin adminhomeplotlist">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="flex-row cell">
            <div className="bodycontainer">
              <div className="flex-column top">
                <div className="emp-dashboard flex flex-column cell nobgform fullwidth">
                  <div className=" flex flex-row end top-gutter">
               
                  <Link
                    to="/addplot"
                    className="ant-btn-primary bottom-gutter self-end"
                  >
                    Add Plot
                  </Link>
                  </div>
                  <PlotList plots={plots} isAdmin={true}></PlotList>

                  <div className="flex end stickybuttonbg ">
                 
                  <Button
                 onClick={() => showModal()}
                    className="ant-btn-secondary bottom-gutter self-end"
                  >
                   Proceed
                  </Button>
                  </div>
            <Modal title="Select Customer" open={isModalOpen} footer={null} onCancel={handleCancel}>
            <SelectCustomer></SelectCustomer>
            </Modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminplotList;
