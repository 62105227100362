import React, { useState, useEffect } from "react";

import {
  Button,
  Checkbox,
  Select,
  Form,
  Input,
  DatePicker,
  CollapseProps,
  UploadProps,
  Upload,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import AdminHeader from "../shared/header";
import {
  getData,
  getProjectDropdownList,
  addData,
  getDataWithPayload,
} from "../../../services/services";
import {
  projectsListAPI,
  addPropertyAPI,
  updatePropertyAPI,
  propertyDetailsByIDAPI,
  errorMessage,
} from "../../../common/constants";
import { useSearchParams } from "react-router-dom";
import ErrorComponent from "../../ErrorComponent/ErrorComponent";

import { Collapse } from "antd";
function AdminAddProperty() {
    
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const [propertyId, setPropertyId] = useState("");
  const [loading, setLoading] = useState(true);
  const[projectList, setProjectList] = useState([]);

  async function fetchPropertyData(property_id) {
    const res = await getDataWithPayload(propertyDetailsByIDAPI, {
      property_id: property_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data[0]);
      setLoading(false);
      console.log(data[0]);
    }
  }

  async function fetchProjects() {
    const res = await getData(projectsListAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = getProjectDropdownList(response.data);
      setProjectList(listItems);
      console.log(listItems);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchProjects();
  }, []);

  useEffect(() => {
    const property_id = searchParams.get("property_id");
    setPropertyId(property_id);
    if (property_id) fetchPropertyData(property_id);
    else setLoading(false);
  }, []);

  const items: CollapseProps[] = [
    {
      key: "1",
      label: "Property Details",
      children: <Location />,
    },
  ];
  const statusList = [
    { value: "active", label: <span>ACTIVE</span> },
    { value: "inactive", label: <span>INACTIVE</span> },
  ];
  function Location() {
    return (
      <div className="flex wrap">
        <Form.Item
          className="cell"
          label="Project"
          name="project_id"
          rules={[
            {
              required: true,
              message: "Please input your Project name!",
            },
          ]}
        >
          <Select options={projectList} />
        </Form.Item>
        <Form.Item
          className="cell"
          label="Property"
          name="property_name"
          rules={[
            {
              required: true,
              message: "Please input your property name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Property Status"
          name="status"
          rules={[
            {
              required: true,
              message: "Please input your status!",
            },
          ]}
        >
          <Select options={statusList}></Select>
        </Form.Item>
        <Form.Item
          label="Property Description"
          name="description"
        
        >
          <Input />
        </Form.Item>
      </div>
    );
  }

  //Form submit method
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      const payload = {
        ...data,
        ...values,
        property_id: data.property_id,
      };

      let endpoint;
      if (data.property_id) {
        endpoint = updatePropertyAPI;
      } else {
        endpoint = addPropertyAPI;
      }
      const response = await addData(endpoint, payload);
      const result = await response.json();
      if (result && result.status === "success") {
        setIsLoading(false);
        window.location.replace("/adminpropertylist");
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  const handleCancel = () => {
    window.location.replace("/adminpropertylist");
    return;
  }
  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };

  if (loading) return <span>Loading...</span>;
  if (!loading)
    return (
      <div className="admin ">
        <div className="flex flex-row fullheight">
          <div className="admin-dashboard">
            <AdminHeader></AdminHeader>

            <div className="bodycontainer emp-view">
              <div className="flex flex-column  accordion bottom-gutter-20">
                <Form
                  className="form"
                  layout="vertical"
                  onFinish={handleSubmit}
                  onFinishFailed={handleErrors}
                  initialValues={{
                    project_id: data.project_id,
                    property_name: data.property_name,
                    status: data.status,
                    description: data.description,
                  }}
                >
                  <div className="flex flex-row vcenter around-gutter">
                    <h1 className="flex-row cell no-gutter">Add Property </h1>
                    
                  </div>
                  <Collapse accordion items={items} defaultActiveKey={["1"]} />
                  <div className="stickybottom flex ">
                  {hasError && <ErrorComponent errorMessage={errorMessage} />}
                    {isLoading && <Spin />}
                    <Button type="secondary self-end right-gutter" htmlType="submit">
                      Save
                    </Button>
                    <Button type="primary" onClick={handleCancel} >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default AdminAddProperty;
