
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import HomeFooter from "./shared/homefooter";
import HomeHeader from "./shared/homeheader";
import sandle from '../../assets/images/sandlewood.jpg';
import bannerimg from '../../assets/images/schemes.jpg';
import logo from '../../assets/images/logoonly.png';
import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
function HomeSchemes() {
    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <HomeHeader />
                <section className="mainbannersec">
                    <img src={bannerimg} />
                    <div className="content">
                        <h4 className="noshade">Srilakshi Offers You Best Plans</h4>
                    </div>
                </section>
                <section className="purchasescheme banbg " id="schemes">
                    <div className="sectioncontent content">


                        <Form className="form flex-row" layout="vertical">
                            <div className="flex-column right-gutter cell">
                                <h1>Plan A</h1>
                                <h2>Out Rate Units for  for <br /><b>10 Cents (484 Square Yards)</b></h2>
                                <div className="flex flex-column plan1">

                                    <Form.Item className='cell'
                                        label="Unit Booking"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="50,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Plot Agreement (before 15 days)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Unit Registration (before 30 days)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="5,00,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Total"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="6,50,000" />
                                    </Form.Item>
                                </div>
                            </div>
                            <div className="flex-column schemelogo">
                                <div className="logostyles">
                                    <figure className="logo" ><img src={logo} /></figure>
                                    {/* <figure className="logotitle"><img src={logotitle} /></figure> */}
                                </div>
                                <h3><b>Sri Lakshmi Agro Farm</b> <br />Scheme Plans</h3>
                            </div>
                            {/* <div className="flex-column left-gutter cell">
                                <h1>Plan B</h1>
                                <h2>Easy Installments  for <br /><b>10 Cents (484 Square Yards)</b></h2>
                                <div className="flex flex-column plan1">
                                    <Form.Item className='cell'
                                        label="Unit Booking"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="50,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Unit Agreement"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="Every Month (10,000 x 20 months)"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="2,00,000" />
                                    </Form.Item>
                                    <h3>Special Installments</h3>
                                    <div className="flex flex-row">
                                    <Form.Item className='cell'
                                        label="6th Month"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="12th Month"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    </div>
                                    <div className="flex flex-row">
                                    <Form.Item className='cell'
                                        label="18th Month"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    <Form.Item className='cell'
                                        label="24th Month"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="1,00,000" />
                                    </Form.Item>
                                    </div>
                                    <Form.Item className='cell'
                                        label="Total"
                                        name="unitbooking"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your firstname!',
                                            },
                                        ]}
                                    >
                                        <Input disabled defaultValue="7,50,000" />
                                    </Form.Item>
                                </div>
                            </div> */}
                        </Form>
                    </div>
                </section>
                <HomeFooter />
            </div>

        </div>


    );
}

export default HomeSchemes;

