
import React, { useState, useEffect } from "react";
import { Space, Table, Tag } from 'antd';

import AdminHeader from '../shared/header';
import { Button, Checkbox, Form, Input, Spin, Select } from "antd";
import EmployeeNav from '../shared/nav';
import plotimage from '../../../assets/images/plot.png';
import { getData } from "../../../services/services";
import { plotsAPI } from "../../../common/constants";

import { Link } from "react-router-dom";
import PurchaseScheme from '../../shared/purchasescheme';
import PlotList from '../../shared/plotlist';
function PropertyList() {
    const [plots, setPlots] = useState([]);

  async function fetchPlots() {
    const res = await getData(plotsAPI);
    const response = await res.json();
    if (response.status === "success") {
      const listItems = response.data;
      setPlots(listItems);
    }
  }

  useEffect(() => {
    fetchPlots();
  }, []);
    return (
        <div className="employee emp-dashboard flex flex-column cell">
            <AdminHeader></AdminHeader>
            <div className='flex-row cell'>
                <EmployeeNav></EmployeeNav>
                <div className='bodycontainer adminhomeplotlist'>
                    <div class="emp-dashboard">
                    <PlotList plots={plots}></PlotList>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default PropertyList;
