
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import logo from '../../assets/images/logoonly.png';
import logotitle from '../../assets/images/logotitle.png';
import banner from '../../assets/images/r-banner-1.jpeg';
import banner1 from '../../assets/images/mainbg1.jpg';
import banner2 from '../../assets/images/mainbg2.jpg';
import banner3 from '../../assets/images/mainbg3.jpg';
import banner4 from '../../assets/images/mainbg6.jpg';
import banner5 from '../../assets/images/mainbg4.jpg';
import banner6 from '../../assets/images/mainbg5.jpg';
import secimg from '../../assets/images/r-img-2.jpeg';
import video from '../../assets/images/video.mp4'
import brochure1 from '../../assets/images/SingleBoruchers-1.jpg';
import brochure2 from '../../assets/images/SingleBoruchers-2.jpg';
import brochure3 from '../../assets/images/SingleBoruchers-3.jpg';
import brochure4 from '../../assets/images/SingleBoruchers-4.jpg';
import brochure5 from '../../assets/images/SingleBoruchers-5.jpg';
import brochure6 from '../../assets/images/SingleBoruchers-6.jpg';

import Hyd from "../../assets/images/hyd.png";
import Vij from "../../assets/images/vij.png";
import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
import { getData, getEmployeeDropdownList, addData } from "../../services/services";
import { adminPlotsListAPI, employeesListAPI, addToCartAPI } from "../../common/constants";
import { Link } from "react-router-dom";
import PlotList from "../shared/plotlist";
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import HomeFooter from "./shared/homefooter";
import Projectlist from "../shared/projectlist";
import HomeHeader from "./shared/homeheader";
import HTMLFlipBook from 'react-pageflip';

const AutoplaySlider = withAutoplay(AwesomeSlider);
function sales() {
    document.querySelector(`#sales`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}


  

  
function Home() {
    const [plots, setPlots] = useState([]);
    async function fetchPlots() {
        const res = await getData(adminPlotsListAPI);
        const response = await res.json();
        if (response.status === "success") {
            const listItems = response.data;
            setPlots(listItems);
        }
    }
    useEffect(() => {
        fetchPlots();
    }, []);
    const [isOpen, setIsOpen] = useState(true);
  
    function toggle() {
      setIsOpen((isOpen) => !isOpen);
    }
    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <HomeHeader />


                <AutoplaySlider play={true}
                    cancelOnInteraction={false} // should stop playing on user interaction
                    interval={6000}>
                    <div data-src={banner1} ><div className="overlay"></div><div className="banner">
                        <h6><span>25 years of continous income</span>
                            <strong>₹ 7,50,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                            <div>Easy Installments
                                <ul>
                                    <li>Pass Books</li>
                                    <li>Spot Registration</li>
                                    <li>Formar Assurance</li>
                                </ul>
                            </div>
                        </h6></div></div>
                    <div data-src={banner2}><div className="overlay"></div>
                        <div data-src={banner1} ><div className="overlay"></div><div className="banner">
                            <h6><span>Investment in Land....Generational Returns</span>
                                <strong>₹ 7,50,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                                <div>Easy Installments
                                    <ul>
                                        <li>Pass Books</li>
                                        <li>Spot Registration</li>
                                        <li>Formar Assurance</li>
                                    </ul>
                                </div>
                            </h6></div></div>
                    </div>
                    <div data-src={banner3}><div className="overlay"></div>
                        <div data-src={banner1} ><div className="overlay"></div><div className="banner">
                            <h6><span>The Idea is Yours....The Practice is Ours</span>
                                <strong>₹ 7,50,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                                <div>Easy Installments
                                    <ul>
                                        <li>Pass Books</li>
                                        <li>Spot Registration</li>
                                        <li>Formar Assurance</li>
                                    </ul>
                                </div>
                            </h6></div></div>
                    </div>
                    <div data-src={banner4} ><div className="overlay"></div><div className="banner">
                        <h6><span>25 years of continous income</span>
                            <strong>₹ 7,50,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                            <div>Easy Installments
                                <ul>
                                    <li>Pass Books</li>
                                    <li>Spot Registration</li>
                                    <li>Formar Assurance</li>
                                </ul>
                            </div>
                        </h6></div></div>
                        <div data-src={banner5}><div className="overlay"></div>
                        <div data-src={banner1} ><div className="overlay"></div><div className="banner">
                            <h6><span>Investment in Land....Generational Returns</span>
                                <strong>₹ 7,50,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                                <div>Easy Installments
                                    <ul>
                                        <li>Pass Books</li>
                                        <li>Spot Registration</li>
                                        <li>Formar Assurance</li>
                                    </ul>
                                </div>
                            </h6></div></div>
                    </div>
                    <div data-src={banner6}><div className="overlay"></div>
                        <div data-src={banner1} ><div className="overlay"></div><div className="banner">
                            <h6><span>The Idea is Yours....The Practice is Ours</span>
                                <strong>₹ 7,50,000 /- <small>10 Cents, 484 Sq. Yds</small></strong>
                                <div>Easy Installments
                                    <ul>
                                        <li>Pass Books</li>
                                        <li>Spot Registration</li>
                                        <li>Formar Assurance</li>
                                    </ul>
                                </div>
                            </h6></div></div>
                    </div>
                </AutoplaySlider>
                <section className="banner bottom-pad-50 " >

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <h3>Our <b>Projects  </b></h3>
                            <Projectlist />
                        </div>
                    </div>
                </section>
                <section className="banner bottom-pad-50 banbg top-pad-100" id="aboutus">

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <h3>The Idea is <b>yours</b> the practis is <b>ours</b></h3>

                            <div className=" flex-row mob-flex-row vcenter">
                                <figure className="cell">
                                    <video width="100%" controls autoPlay>
                                        <source src={video} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                </figure>

                                <div className="flex-column cell">

                                    <p>We lived in a constantly polluted environment. Everyone's desire is to rest in the lap of nature. But land prices are not available for own farming. So, Mea Maa <b>Shree Lakshmi Agro Farms & Projects </b>is giving you an opportunity to do agriculture along with the land Avocado is native to Mexico. </p>
                                    <p>Avocado is a native to Mexico. Avocado is also knows as butter fruit or alligator pear. It also appears an almost every restaurant menu. This avocado, apart from being a health-rich fruit, can also be added to a variety of foods like salads, smoothies, donuts and sandwiches. It is a fruit that is not widely known but has many health benefits. </p>
                                    <p>It is slightly bitter in tast. Has a slight buttery taste. Available in all supermarkets. Avocado fruit plants and red sandalwood plants - way to turn traders into millionares and earn in a short period of time.</p>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>






                <HomeFooter />
            </div>
            {isOpen && 
            <div className="brochure">
                <button className="closemodal"><span class="material-symbols-outlined "  onClick={toggle}>close</span></button>
                <HTMLFlipBook width="600" height={800} >
                    <div className="demoPage"><img src={brochure1} /></div>
                    <div className="demoPage"><img src={brochure2} /></div>
                    <div className="demoPage"><img src={brochure3} /></div>
                    <div className="demoPage"><img src={brochure4} /></div>
                    <div className="demoPage"><img src={brochure5} /></div>
                    <div className="demoPage"><img src={brochure6} /></div>
                </HTMLFlipBook>
            
            </div>
            }
        </div>


    );
}

export default Home;
