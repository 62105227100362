import logo from './logo.svg';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import '../src/assets/stylesheets/css/app.css';

import EmployeeLogin from './components/employee/login/login';
import EmployeeRegistration from './components/employee/registration/registration';
import CustomerLogin from './components/customer/login/login';
import CustomerResetPassword from './components/customer/resetpassword/resetpassword';
import CustomerRegistration from './components/customer/registration/registration';
import AdminLogin from './components/admin/login/login';
import Home from './components/home';
import EmployeeDashboard from './components/employee/dashboard/dashboard';
import EmployeeEmpoyer from './components/employee/employer/employer';
import EmployeeEmpoyerDetails from './components/employee/employer/employerdetails';
import EmployeeResetPassword from './components/employee/resetpassword/resetpassword';

import PaymentMaintenanceList from './components/admin/paymentmaintenance/paymentmaintenancelist.js';
import ConfirmComissionPayment from './components/admin/paymentmaintenance/confirmcomissionpayment.js';
import EmployeeList from './components/admin/employeemaintenance/employeelist.tsx';
import AddEditEmployer from './components/employee/employer/addeditemployer';
import EmpProfile from './components/employee/profile/profile';
import PaymentHistory from './components/employee/paymenthistory/paymenthistory';
import TodayBusiness from './components/employee/todaybusiness/todaybusiness';
import PropertyList from './components/employee/property/propertylist';
import PropertyDetails from './components/employee/property/propertydetails';
import EmployeeViewDetails from './components/admin/employeemaintenance/employeeviewdetails';
import CustomerList from './components/admin/customermaintenance/customerlist.tsx';
import CustomerViewDetails from './components/shared/viewcustomerdetails.js';
import CustomerPurchased from './components/admin/customermaintenance/customerpurchased.js';
import CustomerPayments from './components/admin/customermaintenance/customerpayments.js';
import AddProperty from './components/admin/propertymaintenance/addpropertydetails.js';
import AddPayment from './components/shared/addpaymentdetails.js';

import AddAdminPay from './components/admin/paymentmaintenance/addadminpay.js';
import AddEditPaymentHistory from './components/employee/paymenthistory/AddPaymenthistory.js';
import PlotList from './components/shared/plotlist.js';
import EstateList from './components/admin/propertymaintenance/Estatelist.js';
import ProjectList from './components/admin/propertymaintenance/projectlist.tsx';
import AddProject from './components/admin/propertymaintenance/addproject.js';
import AdminPropertyList from './components/admin/propertymaintenance/adminpropertylist.tsx';
import AdminAddProperty from './components/admin/propertymaintenance/addproperty.js';
import AdminplotList from './components/admin/propertymaintenance/adminplotlist.js';
import AddPlot from './components/admin/propertymaintenance/addplot.js';
import EmployeeSoldDetails from './components/admin/paymentmaintenance/employeesold.js';
import RoleMaintenance from './components/admin/employeemaintenance/rolemaintenance.tsx';
import UpdateRole from './components/admin/employeemaintenance/updaterole.js';
import CustomerViewPlotRegistration from './components/customer/customerviewplotregistration/customerviewplotregistration.js';
import PaymentView from './components/shared/payment.js';
import PayDueAmountModal from './components/shared/payduemodal.js';
import CustomerDashboard from './components/customer/customerdashboard/customerdashboard.js';
import CustomerPropertiesContacts from './components/admin/customermaintenance/customerpropcontacts.js';
import AddEditCustomer from './components/shared/addeditcustomer.js';
// import RegisterNewPlot from './components/shared/registernewplot.js';

import PlotRegistration from './components/shared/plotregistration.js';
import PurchaseCart from './components/admin/propertymaintenance/purchasecart.js';
import ConfirmPayment from './components/shared/confirmpayment.js';
import InvoiceReport from './components/shared/invoicereport.js';
import ChoosePlan from './components/shared/chooseplan.js';
import CustomerReport from './components/shared/customerreport.js';
import EmpAssociationReport from './components/shared/employeeassociationReport.js';
import CustomerNav from './components/shared/customernav.js';
import CustomerPurchaseCart from './components/customer/customerdashboard/customerpurchasecart.js';
import TermsConditions from './components/home/termsandconditions.js';
import HomeSchemes from './components/home/homeschemes.js';
import HomeAvicado from './components/home/avicado.js';
import HomeProjects from './components/home/homeprojects.js';
import HomePlotDetails from './components/home/homeplotdetails.js';
import CurrentProjects from './components/home/currentprojects.js';
import UpcomingProjects from './components/home/upcomingprojects.js';
import CompletedProjects from './components/home/completeprojects.js';



function App() {
  return (
    
    <main>

      <BrowserRouter>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="termsconditions" element={<TermsConditions />} />
      <Route path="schemes" element={<HomeSchemes />} />
      <Route path="avicado" element={<HomeAvicado />} />
      <Route path="homeprojects" element={<HomeProjects />} />
      <Route path='currentprojects' element={<CurrentProjects />} />
      <Route path='upcomingprojects' element={<UpcomingProjects />} />
      <Route path='completedprojects' element={<CompletedProjects />} />
      <Route path="homeplotdetails" element={<HomePlotDetails />} />
      
        {/*employee Links*/}
          <Route path="employeelogin" element={<EmployeeLogin />} />
          <Route path="employeeregistration" element={<EmployeeRegistration />}/>
          <Route path="employeedashboard" element={<EmployeeDashboard />}/>
          <Route path="employers" element={<EmployeeEmpoyer />}/>
          <Route path="employerdetails" element={<EmployeeEmpoyerDetails />}/>
          <Route path="employeeresetpassword" element={<EmployeeResetPassword />}/>
          <Route path="addteammember" element={<AddEditEmployer />}/>
          <Route path="profile" element={<EmpProfile />}/>
          <Route path="paymenthistory" element={<PaymentHistory />}/>
          <Route path="addeditthistory" element={<AddEditPaymentHistory />}/>
          <Route path="todaybusiness" element={<TodayBusiness />}/>
          <Route path="propertylist" element={<PropertyList />}/>
          <Route path="propertydetails" element={<PropertyDetails />}/>
          <Route path="plotlist" element={<PlotList />}/>

        {/*Customer Links*/}
          <Route path="customerlogin" element={<CustomerLogin />} />
          <Route path="customernav" element={<CustomerNav />} />
          <Route path="customerregistration" element={<CustomerRegistration />}/>
          <Route path="customerresetpassword" element={<CustomerResetPassword />}/>
          <Route path="customerdashboard" element={<CustomerDashboard />}/>
          <Route path="Customerviewplotregistration" element={<CustomerViewPlotRegistration />}/>
          <Route path="editcustomerdetails" element={<CustomerPropertiesContacts />}/>
          <Route path="addeditcustomer" element={<AddEditCustomer />}/>
          <Route path="paymentview" element={<PaymentView />}/>
          <Route path="customerpurchasecart" element={<CustomerPurchaseCart />} />
          {/* <Route path="registernewplot" element={<RegisterNewPlot />}/> */}

             {/*Admin Links*/}
          <Route path="adminlogin" element={<AdminLogin />} />
          <Route path="teamlist" element={<EmployeeList />}/>
          <Route path="teamview" element={<EmployeeViewDetails />}/>
          <Route path="soldplots" element={<EmployeeSoldDetails />}/>
          <Route path="customerlist" element={<CustomerList />}/>
          {/* <Route path='customerdetails' element={<CustomerViewDetails />} /> */}
          <Route path='customerpurchased' element={<CustomerPurchased />} />
          <Route path='customerpayments' element={<CustomerPayments />} />
          <Route path="addproperty" element={<AddProperty />}/>
          <Route path="estatelist" element={<EstateList />}/>
          <Route path="projectlist" element={<ProjectList />}/>
          <Route path="addproject" element={<AddProject />}/>
          <Route path="adminpropertylist" element={<AdminPropertyList />}/>
          <Route path="adminaddproperty" element={<AdminAddProperty />}/>
          <Route path="adminplotlist" element={<AdminplotList />}/>
          <Route path="addplot" element={<AddPlot />}/>

          <Route path="rolemaintenance" element={<RoleMaintenance />}/>
          <Route path="updaterole" element={<UpdateRole />}/>
          <Route path="adminaddpayment" element={<AddAdminPay />}/>
          <Route path="paymentmaintenancelist" element={<PaymentMaintenanceList />}/>
          <Route path="confirmcomissionpayment" element={<ConfirmComissionPayment />}/>
          <Route path="paydueamount" element={<PayDueAmountModal />}/>
          <Route path="plotregistration" element={<PlotRegistration />}/>
          <Route path="purchasecart" element={<PurchaseCart />}/>
          <Route path="chooseplan" element={<ChoosePlan />}/>
          <Route path="confirmpayment" element={<ConfirmPayment />}/>
          <Route path="invoicereport" element={<InvoiceReport />}/>
          <Route path="customerreport" element={<CustomerReport />}/>
          <Route path="associationreport" element={<EmpAssociationReport />}/>




      </Routes>
    </BrowserRouter>


     
    </main>
  );
}

export default App;
