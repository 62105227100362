
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import HomeFooter from "./shared/homefooter";
import HomeHeader from "./shared/homeheader";
import bannerimg from '../../assets/images/background7.jpg';
import PlotList from "../shared/plotlist";
import secimg from '../../assets/images/r-img-2.jpeg';
import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
import { getData, getEmployeeDropdownList, addData } from "../../services/services";
import { adminPlotsListAPI, employeesListAPI, addToCartAPI } from "../../common/constants";
function HomePlotDetails() {
    const [plots, setPlots] = useState([]);
    async function fetchPlots() {
        const res = await getData(adminPlotsListAPI);
        const response = await res.json();
        if (response.status === "success") {
            const listItems = response.data;
            setPlots(listItems);
        }
    }
    useEffect(() => {
        fetchPlots();
    }, []);
    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <HomeHeader />
                <section className="mainbannersec">
                    <img src={bannerimg} />
                    <div className="content">
                        <h4 className="noshade">Plots Details</h4>
                    </div>
                </section>
                <section className="banner bottom-pad-50  top-pad-100" id="benefits" >

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <div className="emp-dashboard ">
                                <div className="propertylist ">
                                    <PlotList plots={plots} isAdmin={false}></PlotList>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <HomeFooter />
            </div>

        </div>


    );
}

export default HomePlotDetails;

