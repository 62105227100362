import React from 'react';
import logo from '../../../assets/images/logoonly.png';
import logotitle from '../../../assets/images/logotitle.png';
import brochure from '../../../assets/images/SingleBoruchers.pdf';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { Link } from "react-router-dom";
function home() {
    document.querySelector(`#home`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function scheme() {
    document.querySelector(`#schemes`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function aboutus() {
    document.querySelector(`#aboutus`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function benefits() {
    document.querySelector(`#benefits`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
    
}
function sales() {
    document.querySelector(`#sales`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}
function terms() {
    document.querySelector(`#terms`).scrollIntoView(
        {
            behavior: "smooth"
        }
    )
}

function HomeHeader() {
    return (
<header className="flex-column">
<div className="content flex-row mob-flex-row">
    <div className="logostyles">
        <figure className="logo" ><img src={logo} /></figure>
        <figure className="logotitle"><img src={logotitle} /></figure>
    </div>

    <div className="flex-row mob-flex-row vcenter">
        
        <Link to="/employeelogin" target='_blank' className="right-gutter ant-btn-primary" >
            <span class="material-symbols-outlined ">badge</span> <b>Agent Login</b>
        </Link>
        <Link to="/customerlogin" target='_blank' className="right-gutter ant-btn-secondary" >
            <span class="material-symbols-outlined ">person</span><b>Customer Login</b>
        </Link>
    </div>
</div>
<div className="nav">
<div className="content flex-row middle relative">
    <NavLink to="/" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
        <span class="material-symbols-outlined ">home</span> <b>Home</b>
    </NavLink>
    <NavLink to="/homeprojects" >
        <span class="material-symbols-outlined ">real_estate_agent</span> <b>Our Projects</b><span class="material-symbols-outlined">
keyboard_arrow_down
</span>
        <ul>
            <li> <NavLink to="/currentprojects" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
        <span class="material-symbols-outlined ">real_estate_agent</span> <b>Current Projects</b>
    </NavLink></li>
    <li> <NavLink to="/upcomingprojects" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
        <span class="material-symbols-outlined ">real_estate_agent</span> <b>Upcoming Projects</b>
    </NavLink></li>
    <li> <NavLink to="/completedprojects" className={({ isActive }) => (isActive ? 'active' : 'inactive')}>
        <span class="material-symbols-outlined ">real_estate_agent</span> <b>Completed Projects</b>
    </NavLink></li>
        </ul>
    </NavLink>
    <NavLink to="/schemes" >
        <span class="material-symbols-outlined ">compost</span> <b>Scheme</b>
    </NavLink>
    <NavLink to="/avicado">
        <span class="material-symbols-outlined ">compost</span> <b>Avicado & Benefits</b>
    </NavLink>
    <NavLink to="/termsconditions">
        <span class="material-symbols-outlined ">description</span> <b>Terms & Conditions</b>
    </NavLink>
    <a href={brochure} target='_blank' download className='download'><span class="material-symbols-outlined ">picture_as_pdf</span>Download Brochure</a>

</div>
</div>
</header>
    );
}

export default HomeHeader;