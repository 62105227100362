import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { getDataWithPayload } from "../../services/services";
import { customerDetailsByIDAPI } from "../../common/constants";

function CustomerNav() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [customerData, setCustomerData] = useState({});
  const [IamAdmin, setIamAdmin] = useState(false);

  async function fetchCustomerData(id) {
    const res = await getDataWithPayload(customerDetailsByIDAPI, {
      customer_id: id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setCustomerData(response.data);
    }
  }

  useEffect(() => {
    const customer_id = searchParams.get("customer_id");
    if (customer_id) {
      fetchCustomerData(customer_id);
    }
    const adminDetails = JSON.parse(localStorage.getItem("adminUserDetails"));
    if (adminDetails?.isAdmin) {
      setIamAdmin(true);
    }
  }, []);

  if (customerData?.name)
    return (
      <figure className="cell flex-column vcenter">
        <img
          src={
            "https://srilakshmiagrofarms.co.in/webapplication/uploads/customers/" +
            customerData.customer_pic
          }
          alt=""
        />
        <figcaption>{customerData.name} </figcaption>
        <ul className="inner-menu">
          {IamAdmin && (
            <>
              <li>
                <Link
                  to={
                    "/editcustomerdetails?customer_id=" +
                    customerData.customer_id
                  }
                >
                  {" "}
                  <span class="material-symbols-outlined size-14">Edit</span>
                  Edit Customer
                </Link>
              </li>
              <li>
                <Link to={"/customerpayments?cust_id=" + customerData.cust_id}>
                  <span class="material-symbols-outlined size-14">
                    currency_rupee
                  </span>{" "}
                  Payment Details
                </Link>
              </li>
              <li>
                <Link to={"/PurchaseCart?cust_id=" + customerData.cust_id}>
                  <span class="material-symbols-outlined size-14">
                    currency_rupee
                  </span>{" "}
                  Customer Cart
                </Link>
              </li>
            </>
          )}

          {!IamAdmin && (
            <>
              <li>
                <Link to={"/customerdashboard?customer_id=" + customerData.cust_id}>
                  <span class="material-symbols-outlined size-14">
                   dashboard
                  </span>{" "}
                  Dashboard
                </Link>
              </li>
              <li>
                <Link
                  to={"/customerpurchasecart?customer_id="+customerData.cust_id+"&cust_id=" + customerData.cust_id}
                >
                  <span class="material-symbols-outlined size-14">
                    currency_rupee
                  </span>{" "}
                  Your Cart
                </Link>
              </li>
            </>
          )}
        </ul>
      </figure>
    );
}

export default CustomerNav;
