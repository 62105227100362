import React, { useEffect, useState } from "react";
import { Form, Select, Input, Button } from "antd";
import { Link } from "react-router-dom";
import { formatPrice, addData } from "../../services/services";
import { useSearchParams } from "react-router-dom";

import {
  addOrderDetailsAPI,
  orderDetailsByIDAPI,
  updateOrderDetailsAPI,
  gstPercentage,
} from "../../common/constants";

import { getDataWithPayload } from "../../services/services";

function ConfirmPayment() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [cartItem, setCartItem] = useState({});
  const [amount, setAmount] = useState(0);
  const [gst, setGst] = useState(0);
  const [paymentMode, setPaymentMode] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [oid, setOid] = useState("");
  const [showNumberOfEmis, setShowNumberOfEmis] = useState(false);
  const [showSpecialEmiType, setShowSpecialEmiType] = useState(false);
  const [numberofPayingEmis, setNumberofPayingEmis] = useState(1);
  const [totalPayingAmount, setTotalPayingAmount] = useState(0);
  const [paymentTypeOptions, setPaymentTypeOptions] = useState([]);
  const [showOtherAmountField, setShowOtherAmountField] = useState(false);
  const [specialEmitype, setSpecialEmitype] = useState("");
  const [numberOfSpecialEmisType, setNumberOfSpecialEmisType] = useState("");
  async function fetchOrderItem(orderId) {
    const res = await getDataWithPayload(orderDetailsByIDAPI, {
      orderid: orderId,
    });
    const response = await res.json();
    if (response.status === "success") {
      setCartItem(response.data[0]);
      settingPlanTypeOptions(response.data[0]);
      getSpecialEmisType(response.data[0]);
    }
  }

  const settingPlanTypeOptions = (cartItemNew) => {
    if (cartItemNew.plan_type == "planB") {
      if (
        cartItemNew.emi_booking_amount_paid == false || cartItemNew.emi_booking_amount_paid == 0 || cartItemNew.emi_booking_amount_paid == '0' ||
        cartItemNew.emi_booking_amount_paid == "false"
      )
        setPaymentTypeOptions([
          {
            value: "Booking",
            label: "Booking",
          },
        ]);
      else {
        const options = [
          {
            value: "Registration",
            label: "Registration",
          }
        ];
        if(cartItemNew.pending_emis > 0)
          options.push({
           value: "Emi",
           label: "Emi",
         });
        if(cartItemNew.sixth_special_emi_amount_paid == 0 || cartItemNew.twelth_special_emi_amount_paid == 0 || cartItemNew.eighteenth_special_emi_amount_paid == 0 || cartItemNew.twentyfourth_special_emi_amount_paid == 0)
           options.push({
            value: "Special",
            label: "Special Emi",
          });
        
          setPaymentTypeOptions(options);
        }
    } else {
      if (
        cartItemNew.booking_amount_paid == false || cartItemNew.booking_amount_paid == 0 || cartItemNew.booking_amount_paid == '0' ||
        cartItemNew.booking_amount_paid == "false"
      )
        setPaymentTypeOptions([
          {
            value: "Booking",
            label: "Booking",
          },
        ]);
      else if (
        cartItemNew.agreement_amount_paid == true ||
        cartItemNew.agreement_amount_paid == "true"
      )
        setPaymentTypeOptions([
          {
            value: "Registration",
            label: "Registration",
          },
        ]);
      else
        setPaymentTypeOptions([
          {
            value: "Agreement",
            label: "Agreement",
          },
          {
            value: "Registration",
            label: "Registration",
          },
        ]);
    }
  };

  useEffect(() => {
    const orderId = searchParams.get("orderId");
    if (orderId) {
      setOid(orderId);
      fetchOrderItem(orderId);
    } else {
      const Item = JSON.parse(localStorage.getItem("cartItem"));
      settingPlanTypeOptions(Item);
      setCartItem(Item);
    }
  }, []);

  useEffect(() => {
    const gstAmount = (Number(gstPercentage) * Number(amount)) / 100;
    setGst(gstAmount);
    setTotalPayingAmount(Number(amount));
  }, [amount]);
  const paymentModeOptions = [
    {
      value: "Cash",
      label: "Cash",
    },
    {
      value: "Online",
      label: "Online",
    },
  ];
  const numberOfEmisListOptions = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },

    {
      value: "6",
      label: "6",
    },
    {
      value: "7",
      label: "7",
    },
    {
      value: "8",
      label: "8",
    },
    {
      value: "9",
      label: "9",
    },
    {
      value: "10",
      label: "10",
    },
  ];
const getSpecialEmisType = (cartItemNew) => {
  if (cartItemNew.sixth_special_emi_amount_paid != 1 )
  setNumberOfSpecialEmisType([
    {
      value: "6th",
      label: "6th Month",
    }]);
  else if(cartItemNew.twelth_special_emi_amount_paid != 1)
    setNumberOfSpecialEmisType([{
      value: "12th",
      label: "12th Month",
    }]);
    else if(cartItemNew.eighteenth_special_emi_amount_paid != 1)
      setNumberOfSpecialEmisType([{
      value: "18th",
      label: "18th Month",
    }]);
    else
      setNumberOfSpecialEmisType([{
      value: "24th",
      label: "24th Month",
    }]);
}

  const GetTickMark = (props) => {
    const { flag } = props;
    const Item =
      flag == "true" || flag == true ? (
        <span class="material-symbols-outlined">check_circle</span>
      ) : (
        <span class="material-symbols-outlined cancel">cancel</span>
      );
    return Item;
  };

  const handleConfirmPayment = async () => {
    if (amount <= 0 || paymentMode == "" || paymentType == "") {
      alert("Please enter required fields");
      return;
    }
    if (paymentType == "Other" && Number(amount) < 10000) {
      alert("Amoount should be >= 10000");
      return;
    }
    let isEmi = false;
    if(paymentType == 'Emi') {
      isEmi = true;
    }
    try {
      const cartitem = cartItem;
      const payload = {
        payment_type: paymentType,
        payment_mode: paymentMode,
        plantype: cartitem.plan_type,
        amount: amount,
        paying_emis: isEmi ? numberofPayingEmis : 0,
        special_emi_type:specialEmitype,
        gst: gst,
      };
      let endpoint = "";
      if (oid) {
        payload.orderid = oid;
        endpoint = updateOrderDetailsAPI;
      } else {
        payload.cartItemId = cartitem.id;
        endpoint = addOrderDetailsAPI;
      }

      const response = await addData(endpoint, payload);
      const result = await response.json();
      if (result && result.status == "Success") {
        localStorage.removeItem("cartItem");
        localStorage.removeItem("bookedPlots");
        const adminDetails = localStorage.getItem("adminUserDetails");
        const aDetails = adminDetails ? JSON.parse(adminDetails) : {}
        const customerDetails = localStorage.getItem("customerDetails");
        const cDetails = customerDetails ? JSON.parse(customerDetails) : {};
        const cId = cDetails ? cDetails.customer_id : 0;
        if(aDetails?.isAdmin)
          window.location.replace("/paymentmaintenancelist");
        else 
          window.location.replace("/CustomerDashboard?customer_id="+cId);
        return;
      } else {
        console.log("api failure");
      }
    } catch {
      console.log("Catch block api error!");
    }
  };

  const handleAmountchange = (e) => {
    setAmount(e.target.value);
  };

  const handlePaymentMode = (value) => {
    setPaymentMode(value);
  };

  const handleOtherAmount = (e) => {
    setAmount(e.target.value);
    return;
  };
  const handlePaymentType = (value, planType) => {
    setPaymentType(value);
    setShowOtherAmountField(false);
    setShowNumberOfEmis(false);
    setShowSpecialEmiType(false);
    setAmount(0);
    if (planType == "Other") {
      if (value != "Registration") {
        setShowOtherAmountField(true);
      } else {
        setAmount(cartItem.total_amount_due);
      }
      return;
    }
    if (value == "Emi") {
      setShowNumberOfEmis(true);
      return;
    } 
    else if (value == 'Special') {
      setAmount(100000);
      setShowSpecialEmiType(true);
      return;
    } else {
      setShowNumberOfEmis(false);

      if (cartItem.plan_type == "planA") {
        if (value == "Booking") setAmount(cartItem.booking_amount);
        else if (value == "Agreement") setAmount(cartItem.agreement_amount);
        else {
          setAmount(cartItem.total_amount_due);
          setNumberofPayingEmis(cartItem.pending_emis);
        }
      } else {
        if (value == "Booking") setAmount(cartItem.emi_booking_amount);
        else {
          setAmount(cartItem.total_amount_due);
          setNumberofPayingEmis(cartItem.pending_emis);
        }
      }
    }
  };

  const handleNumberOfEmi = (value) => {
    setNumberofPayingEmis(value);
    setAmount(Number(value) * Number(cartItem.emi_amount));
  };

  const handleSpecialEmiType = (value) => {
    setSpecialEmitype(value);
  };
  return (
    <div className="customerdashboard flex flex-row cell">
      <div className="customerbody ">
        <section className="paysection">
          <h2>Confirm Payment</h2>
          <div className="flex flex-row cell">
            <div className="flex flex-column cell paiddet">
              {cartItem.plan_type != "Other" && (
                <div>
                  <dl>
                    <dt> UNIT BOOKING </dt>
                    <dd>
                      {cartItem.plan_type == "planA"
                        ? formatPrice(cartItem.booking_amount)
                        : formatPrice(cartItem.emi_booking_amount)}
                    </dd>
                    <GetTickMark
                      flag={
                        cartItem.plan_type == "planA"
                          ? cartItem.booking_amount_paid
                          : cartItem.emi_booking_amount_paid
                      }
                    />
                  </dl>
                  {cartItem.plan_type == "planA" ? (
                    <dl>
                      <dt>PLOT AGREEMENT (BEFORE 15 DAYS)</dt>
                      <dd>{formatPrice(cartItem.agreement_amount)}</dd>
                      <GetTickMark flag={cartItem.agreement_amount_paid} />
                    </dl>
                  ) : (
                    <>
                    <dl>
                      <dt>
                        EVERY MONTH ({cartItem.emi_amount} X{" "}
                        {cartItem.number_of_emis} MONTHS)
                      </dt>
                      <dd>
                        {formatPrice(
                          Number(cartItem.emi_amount) *
                            Number(cartItem.number_of_emis)
                        )}
                      </dd>
                    </dl>
                    <dl>
                      <dt>
                         SPECIAL EMI(6th,12th,18th & 24th)
                      </dt>
                      <dd>
                        {formatPrice(Number(400000))}
                      </dd>
                      <Link
                        to={"/addplot?plot_id=" + cartItem.plot_id}
                        className="ant-btn-primary bottom-gutter self-end"
                      >
                        EMI Details
                      </Link>
                      <span class="material-symbols-outlined cancel">
                        cancel
                      </span>
                    </dl>
                    </>
                  )}
                  {cartItem.plan_type == "planA" ? (
                    <dl>
                      <dt>UNIT REGISTRATION (BEFORE 30 DAYS)</dt>
                      <dd>{formatPrice(cartItem.registration_amount)}</dd>
                      <GetTickMark flag={cartItem.registration_amount_paid} />
                    </dl>
                  ) : (
                    <dl>
                      <dt>DURING REGISTRATION (Last MONTH)</dt>
                      <dd>{formatPrice(cartItem.emi_registration_amount)}</dd>

                      <GetTickMark
                        flag={cartItem.emi_registration_amount_paid}
                      />
                    </dl>
                  )}
                </div>
              )}
              <dl>
                <dt>Total</dt>
                <dd>{formatPrice(cartItem.total_amount)}<small>(Incl. Taxes)</small></dd>
              </dl>
            </div>
            <div className="flex flex-column billsumsec">
              <h6>Total Amount Details</h6>
              <dl>
                <dt>Plan </dt>
                <dd>
                  {cartItem.plan_type == "Other"
                    ? "Book a new unit"
                    : cartItem.plan_type}
                </dd>
              </dl>
              <dl>
                <dt>Plot Price</dt>
                <dd>{formatPrice(cartItem.total_amount)}<small>(Incl. Taxes)</small></dd>
              </dl>
              <dl>
                <dt>Total Due Amount</dt>

                <dd>
                  <Input disabled value={"₹ " + cartItem.total_amount_due} />
                  <small>(Incl. Taxes)</small>
                </dd>
              </dl>
              <h6>Payment Type</h6>
              <Form.Item
                label="Payment Mode"
                name="payment_mode"
                rules={[
                  {
                    required: true,
                    message: "Please input your Payment mode!",
                  },
                ]}
              >
                <Select
                  options={paymentModeOptions}
                  onSelect={(value) => handlePaymentMode(value)}
                ></Select>
              </Form.Item>

              <Form.Item
                label="Payment Type"
                name="payment_type"
                rules={[
                  {
                    required: true,
                    message: "Please input your payment type!",
                  },
                ]}
              >
                <Select
                  options={paymentTypeOptions}
                  onSelect={(value) =>
                    handlePaymentType(value, cartItem.plan_type)
                  }
                ></Select>
              </Form.Item>
              {showOtherAmountField && (
                <Form.Item
                  label="Enter amount manually"
                  name="other_amount"
                  rules={[
                    {
                      required: true,
                      message: "Please input your amount!",
                    },
                  ]}
                >
                  <Input onChange={handleOtherAmount} />
                </Form.Item>
              )}

              {showNumberOfEmis && (
                <Form.Item
                  label="Number of Paying Emi's"
                  name="number_of_paying_emis"
                  rules={[
                    {
                      required: true,
                      message: "Please input your payment type!",
                    },
                  ]}
                >
                  <Select
                    options={numberOfEmisListOptions}
                    onSelect={(value) => handleNumberOfEmi(value)}
                  ></Select>
                </Form.Item>
              )}
              {showSpecialEmiType && (
                <Form.Item
                  label="Special Emi type"
                  name="special_emi_type"
                  rules={[
                    {
                      required: true,
                      message: "Please input your special emi type!",
                    },
                  ]}
                >
                  <Select
                    options={numberOfSpecialEmisType}
                    onSelect={(value)=>handleSpecialEmiType(value)}
                  ></Select>
                </Form.Item>
              )}
              <h6>Payment</h6>
              <dl>
                <dt>Amount</dt>
                <dd>
                  <span>{formatPrice(amount)}</span>
                </dd>
              </dl>
              {/* <dl>
                <dt>GST %</dt>
                <dd>
                  {formatPrice(gst)}
                </dd>
              </dl> */}
              <dl className="total">
                <dt>Total Amount</dt>
                <dd>
                  <span>{formatPrice(totalPayingAmount)}</span>
                </dd>
              </dl>
              <div className="flex flex-row end top-gutter-20 fullwidth">
                <Link
                  className="ant-btn-primary right-gutter"
                  to="/adminplotlist"
                >
                  Cancel Payment
                </Link>
                <Button
                  type="secondary"
                  className="self-end"
                  onClick={handleConfirmPayment}
                >
                  Confirm Payment
                </Button>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default ConfirmPayment;
