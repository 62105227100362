import React, {useEffect, useState} from 'react';
import logo from '../../../assets/images/logoonly.png';
import logotitle from '../../../assets/images/logotitle.png';
import { Button } from 'antd';
import CustomerNav from '../../shared/customernav';
import { Link } from "react-router-dom";

function CustomerHeader() {
  const [customerData, setCustomerData] = useState({});
  useEffect(() => {
    const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
    if (!customerDetails || !customerDetails.name) {
      window.location.replace("/customerlogin");
      return;
    }
    setCustomerData(customerDetails);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("customerDetails");
    window.location.replace("/customerlogin");
    return;
  }


  return (

    <header>
      <div className="logostyles flow-row">
        <figure className="logo" ><img src={logo} /></figure>
        <figure className="logotitle"><img src={logotitle} /></figure>
      </div>
<div className='cell customerdashnav'>
  <CustomerNav></CustomerNav>
</div>
      
      <div className='loggedinuser'>
        {/* <div className='flex flex-row userdet'>
        <Button className='iconbutton'>
          <span class="material-symbols-outlined size-72">
            person
          </span>
         
        </Button>
        {customerData.name}
        </div> */}
        <div className='flex flex-row userdet'  onClick={handleLogout}>
          <Button className='iconbutton'>
          <span className="material-symbols-outlined size-72">
            Logout
          </span>
        </Button>
        <b>Logout</b>
        </div>
      </div>

    </header>


  );
}

export default CustomerHeader;
