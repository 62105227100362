
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import HomeFooter from "./shared/homefooter";
import HomeHeader from "./shared/homeheader";
import bannerimg from '../../assets/images/banner.jpg';

import secimg from '../../assets/images/r-img-2.jpeg';
import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
function HomeAvicado() {
    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <HomeHeader />
                <section className="mainbannersec">
                    <img src={bannerimg} />
                    <div className="content">
                        <h4 className="noshade">Avicado Health benefits</h4>
                    </div>
                </section>
                <section className="banner bottom-pad-50  top-pad-100" id="benefits" >

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <h3>Health <b>Benefits</b></h3>
                            <div className=" flex-row mob-flex-row vcenter">


                                <div className="flex-column cell">

                                    <p>Avocado is a native to Mexico. Avocado is also knows as butter fruit or alligator pear. It also appears an almost every restaurant menu. This avocado, apart from being a health-rich fruit, can also be added to a variety of foods like salads, smoothies, donuts and sandwiches. It is a fruit that is not widely known but has many health benefits.</p>
                                    <ul>
                                        <li>Helps with weight loss.</li>
                                        <li>Lower cholesterol.</li>
                                        <li>Regulates sugar levels in the blood</li>
                                        <li>Can reduce eye disorders like cataracts, and eye spots.</li>
                                        <li>Has the ability to inhabit the growth of prostate cancer cells</li>
                                        <li>Promotes fertility</li>
                                        <li>Helps in reducing mental disorders like depression and anxiety.</li>
                                        <li>Relieves arthritis symptoms</li>
                                        <li>Checks digestive problems</li>
                                        <li>Also prevents stretch marks on the skin</li>
                                        <li>It also has the property of controlling hypertension.</li>
                                    </ul>
                                </div>
                                <figure className="cell vcenter flex middle">
                                    <img src={secimg} width={"50%"} />
                                </figure>
                            </div>
                        </div>
                    </div>

                </section>
                <HomeFooter />
            </div>

        </div>


    );
}

export default HomeAvicado;

