
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import HomeFooter from "./shared/homefooter";
import HomeHeader from "./shared/homeheader";
import bannerimg from '../../assets/images/r-banner-4.jpeg';
import Projectlist from "../shared/projectlist";
import { Link } from "react-router-dom";

import secimg from '../../assets/images/r-img-2.jpeg';
import { Button, Checkbox, Select, Form, Input, DatePicker, CollapseProps, UploadProps, Upload, Radio } from 'antd';
function CurrentProjects() {
    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <HomeHeader />
                <section className="mainbannersec">
                    <img src={bannerimg} />
                    <div className="content">
                        <h4 className="noshade">Current Projects</h4>
                    </div>
                </section>
                <section className="banner bottom-pad-50 " >

                    <div className="content flex-column vcenter">

                        <div className="sectioncontent">
                            <h3>Current <b>Projects  </b></h3>
                            <Projectlist />
                        </div>
                    </div>
                </section>
                <HomeFooter />
            </div>

        </div>


    );
}

export default CurrentProjects;

