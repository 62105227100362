import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getData } from "../../services/services";
import { propertiesListAPI } from "../../common/constants";

function Projectlist() {
  const [propertiesList, setPropertiesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  async function fetchProperties() {
    const res = await getData(propertiesListAPI);
    const response = await res.json();
    if (response.status === "success") {
      setPropertiesList(response.data);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchProperties();
  }, []);

  const ActiveProjects = (propertiesList) => {
    const { projects } = propertiesList;
    if (projects.length === 0) return null;
    return (
      <div className="flex flex-row wrap projectlist">
        {projects.map((property) => {
          return (
            <>
              {property.status === "active" && (
                <article>
                  <Link to="/homeplotdetails">
                    <figure></figure>
                    <h2>{property.property_name}</h2>
                    <p>{property.project_name}</p>
                  </Link>
                </article>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const CurrentProjects = (propertiesList) => {
    const { projects } = propertiesList;
    if (projects.length === 0) return null;
    return (
      <div className="flex flex-row wrap projectlist">
        {projects.map((property) => {
          return (
            <>
              {property.status === "current" && (
                <article>
                  <Link to="/homeplotdetails">
                    <figure></figure>
                    <h2>{property.property_name}</h2>
                    <p>{property.project_name}</p>
                  </Link>
                </article>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const UpcomingProjects = (propertiesList) => {
    const { projects } = propertiesList;
    if (projects.length === 0) return null;
    return (
      <div className="flex flex-row wrap projectlist">
        {projects.map((property) => {
          return (
            <>
              {property.status === "upcoming" && (
                <article>
                  <Link to="/homeplotdetails">
                    <figure></figure>
                    <h2>{property.property_name}</h2>
                    <p>{property.project_name}</p>
                  </Link>
                </article>
              )}
            </>
          );
        })}
      </div>
    );
  };

  const CompletedProjects = (propertiesList) => {
    const { projects } = propertiesList;
    if (projects.length === 0) return null;
    return (
      <div className="flex flex-row wrap projectlist">
        {projects.map((property) => {
          return (
            <>
              {property.status === "completed" && (
                <article>
                  <Link to="/homeplotdetails">
                    <figure></figure>
                    <h2>{property.property_name}</h2>
                    <p>{property.project_name}</p>
                  </Link>
                </article>
              )}
            </>
          );
        })}
      </div>
    );
  };

  if (isLoading) return <span>Loading....</span>;
  if (!isLoading)
    return (
      <>
        <ActiveProjects projects={propertiesList} />
        <CurrentProjects projects={propertiesList} />
        <UpcomingProjects projects={propertiesList} />
        <CompletedProjects projects={propertiesList} />
      </>
    );
}

export default Projectlist;

<div className="flex flex-row wrap projectlist">
  <article>
    <Link to="/homeplotdetails">
      <figure></figure>
      <h2>Culture main Project</h2>
      <p>Hyderabad</p>
    </Link>
  </article>
  <article>
    <Link to="/homeplotdetails">
      <figure></figure>
      <h2>Culture main Project</h2>
      <p>Hyderabad</p>
    </Link>
  </article>
  <article>
    <Link to="/homeplotdetails">
      <figure></figure>
      <h2>Culture main Project</h2>
      <p>Hyderabad</p>
    </Link>
  </article>
  <article>
    <Link to="/homeplotdetails">
      <figure></figure>
      <h2>Culture main Project</h2>
      <p>Hyderabad</p>
    </Link>
  </article>
  <article>
    <Link to="/homeplotdetails">
      <figure></figure>
      <h2>Culture main Project</h2>
      <p>Hyderabad</p>
    </Link>
  </article>
  <article>
    <Link to="/homeplotdetails">
      <figure></figure>
      <h2>Culture main Project</h2>
      <p>Hyderabad</p>
    </Link>
  </article>
</div>;
