
import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import HomeFooter from "./shared/homefooter";
import HomeHeader from "./shared/homeheader";
import sandle from '../../assets/images/sandlewood.jpg';
import bannerimg from '../../assets/images/terms.jpg';
function TermsConditions() {
    return (

        <div className="home flex-column mainhome">

            <div className="container " id="home">
                <HomeHeader />
               <section className="mainbannersec">
                    <img src={bannerimg} />
                    <div className="content">
                        <h4>Terms and Conditions</h4>
                    </div>
                </section> 
                <section className="sectioncontent terms" id="terms">
                    <div className="content">
                        <h3><b>Terms</b> & <b></b>Conditions</h3>
                        <div className=" flex-row mob-flex-row">
                            <figure>
                                <img src={sandle} />
                            </figure>
                            <ul>
                                <li>One Unit is 10 cents / 484 Square yards / 4356 sare feet, 20 avocado plants and 10 red sandanlwood are given in one unit.</li>
                                <li>The amount is non-refundable for those who opt out of an installment plan. And for those who drop out in two installments your money will be given after 60 days.</li>
                                <li>Those who bought in unit out rate through Avocado will get income from 5th year. And those who bought in easy installments will be given income in the 6the year through Avacado trees. </li>
                                <li>50% of the revenue generated goes to our valued customer and 50% goes to the company for the maintenance</li>
                                <li>Applies you have to bear the taxes as per the government rules on the income received by you.</li>
                                <li>A crop MOU (Memorandum of Underbonding) will be entered into between the city Unit holder and the company for 10 years (Renewal agreement will be given every 10 years).</li>
                                <li>Customer who take up the unit will be given free Club House Lifetime membership at the rate of 5 days per year. (This provision is applicable aftr 4 years)</li>
                                <li>Your unit can be tranferred any where in upcoming projects of <b>Sri Lakshmi Agro Farms & Projects.</b></li>
                                <li>You can also sell or tranfer to others</li>
                                <li>One unit i.e. 10 cents farmer's relative and passbook will be given as per Govt.</li>
                                <li>Attractive draw functions are performed</li>
                                <li>24/7 security, entrance arse, entrance compound wall, whole site diamond fencing is provided. </li>
                                <li>The resort facilities include smimming pool, children's play area, cottages, convention hall, club house and many more facilities</li>
                                <li>Aggreement with plot number will be given to those who have paid 25%</li>
                                <li>You have to bear the unit registration charges as per Govt.</li>
                                <li>Our unit is provided with wide 40 feet and 30 feet roads</li>
                                <li>130 feet roads and 40 feed roads corner bits will have to pay an additional Rs. 200/-</li>


                            </ul>
                        </div>
                    </div>
                </section>
                <HomeFooter />
            </div>

        </div>


    );
}

export default TermsConditions;

