import React, { useState, useEffect } from "react";

import {
  Button,
  Form,
  Input,
  Spin
} from "antd";
import AdminHeader from "../shared/header";

import { Collapse } from "antd";
import {
    addRoleAPI,
    errorMessage,
    updateRoleAPI,
    roleDetailsByIdAPI,
  } from "../../../common/constants";
  import ErrorComponent from "../../ErrorComponent/ErrorComponent";
  import { addData, getDataWithPayload } from "../../../services/services";
  import { useSearchParams } from "react-router-dom";
function UpdateRole() {
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [roleId, setRoleId] = useState("");
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  async function fetchRoleData(role_id) {
    const res = await getDataWithPayload(roleDetailsByIdAPI, {
      id: role_id,
    });
    const response = await res.json();
    if (response.status === "success") {
      setData(response.data[0]);
      setLoading(false);
    }
  }
  useEffect(() => {
    const role_id = searchParams.get("role_id");
    setRoleId(role_id);
    if (role_id) fetchRoleData(role_id);
    else setLoading(false);
  }, [searchParams]);
  const items: CollapseProps[] = [
    {
      key: "1",
      label: "Update Role",
      children: <RoleUpdate />,
    },
  ];

  function RoleUpdate() {
    return (
      <div className="flex wrap">
        <Form.Item
          className="cell"
          label="Designation"
          name="role_title"
          rules={[
            {
              required: true,
              message: "Please input role!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="cell"
          label="Plan A Percentage"
          name="role_percentage_plan_a"
          rules={[
            {
              required: true,
              message: "Please input percentage!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className="cell"
          label="Plan B Percentage"
          name="role_percentage_plan_b"
          rules={[
            {
              required: true,
              message: "Please input percentage!",
            },
          ]}
        >
          <Input />
        </Form.Item>
      </div>
    );
  }
  const handleSubmit = async (values) => {
    try {
      setIsLoading(true);
      setHasError(false);
      const role_id = searchParams.get("role_id");
      const payload = {
        ...data,
        ...values,
      };

      let endpoint;
      if (role_id) {
        endpoint = updateRoleAPI;
      } else {
        endpoint = addRoleAPI;
      }
      const response = await addData(endpoint, payload);
      const result = await response.json();
      if (result && result.status === "success") {
        setIsLoading(false);
        window.location.replace("/rolemaintenance");
        return;
      } else {
        setHasError(true);
        setIsLoading(false);
        console.log("api failure");
      }
    } catch {
      setIsLoading(false);
      setHasError(true);
      console.log("Catch block api error!");
    }
  };

  //Error handling
  const handleErrors = (errorInfo) => {
    console.log("error in form validation!");
    return false;
  };
  if (loading) return <span>Loading....</span>;
  if (!loading)
  return (
    <div className="admin ">
      <div className="flex flex-row fullheight">
        <div className="admin-dashboard">
          <AdminHeader></AdminHeader>

          <div className="bodycontainer emp-view">
            <div className="flex flex-column  accordion bottom-gutter-20">
              <div className="flex flex-row vcenter around-gutter"></div>

              <Form
                className="form"
                layout="vertical"
                onFinish={handleSubmit}
                onFinishFailed={handleErrors}
                initialValues={{
                  role_title: data.role_title,
                  role_percentage_plan_a: data.role_percentage_plan_a,
                  role_percentage_plan_b: data.role_percentage_plan_b,
                  
                }}
              >
                <div className="flex bottom-gutter">
                <h1 className="flex-row cell no-gutter">Add / Update Role & Percentage </h1>
                
                </div>
                <Collapse accordion items={items} defaultActiveKey={["1"]} />
                <div className="stickybottom">
                {hasError && <ErrorComponent errorMessage={errorMessage} />}
                    {isLoading && <Spin />}
                <Button htmlType="submit" type="primary self-end">
                  Save
                </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateRole;
