import React from 'react';
import logo from '../../../assets/images/logoonly.png';
import logotitle from '../../../assets/images/logotitle.png';
import { Link } from "react-router-dom";
function HomeFooter() {
    return (
        <footer>
            <div className='flex-row content'>
            <div className='flex-column leftfoot'>
                <div className="logostyles">
                    <figure className="logo" ><img src={logo} /></figure>
                    {/* <figure className="logotitle"><img src={logotitle} /></figure> */}
                </div>
                <p> <b>Shree Lakshmi Agro Farms & Projects</b> is giving you an opportunity to do agriculture along with the land Avocado is native to Mexico</p>
            </div>
            <div className='homefootlinks cell'>
                <ul>
                    <li><b>About Srilakshmi</b>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><Link to="/avicado" >Avicado & Benefits</Link></li>
                            <li><Link to="/schemes" >Schemes</Link></li>
                        </ul>
                    </li>
                    <li><b>Projects</b>
                        <ul>
                            <li><Link to="/homeprojects" >All Projects</Link></li>

                        </ul>
                    </li>
                    <li><b>Others</b>
                        <ul>
                            <li><Link to="/termsconditions" >Terms & Conditions</Link></li>

                        </ul>
                    </li>
                </ul>

            </div>
            </div>
            <strong>© 2024 <b>&nbsp;SRI LAKSHMI AGRO FARMS</b>. &nbsp;All Rights Reserved</strong>
        </footer>
    );
}

export default HomeFooter;
